
import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";
import DateTimeConverter from "@/services/converters/DateTimeConverter";
import {DateTime} from "luxon";
import Log from "@/models/Log";

export enum SynchroType {
    DISTRIBUTORS = "DISTRIBUTORS",
    PRODUCTS = "PRODUCTS",
    INVOICES = "INVOICES",
    COMPANY = "COMPANY",
    BRANDS = "BRANDS"

}

export enum SynchroStatus {
    FINISHED = "FINISHED",
    IN_PROGRESS = "IN_PROGRESS",
    CANCELLED = "CANCELLED"
}

@JsonConverter
class SynchroConverter implements JsonCustomConvert<SynchroType> {
    deserialize(data: string): SynchroType { return (<any>SynchroType)[data] }
    serialize(data: SynchroType): any { return data.toString() }
}

@JsonConverter
class StatusConverter implements JsonCustomConvert<SynchroStatus> {
    deserialize(data: string): SynchroStatus { return (<any>SynchroStatus)[data] }
    serialize(data: SynchroStatus): any { return data.toString() }
}

@JsonObject("Synchro")
export default class Synchro {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("type", SynchroConverter, true)
    type?: SynchroType = undefined
    @JsonProperty("starts", DateTimeConverter, true)
    starts?: DateTime = undefined
    @JsonProperty("finish", DateTimeConverter, true)
    finish?: DateTime = undefined
    @JsonProperty("status", StatusConverter, true)
    status?: SynchroStatus = undefined
    @JsonProperty("log", Log, true)
    log?: Log = undefined

}