






























import Handler from '@/handlers/Handler';
import SynchroService from '@/services/SynchroService';
import LangModule from '@/store/LangModule';
import SnackbarModule from '@/store/SnackbarModule';
import { Component, PropSync, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

@Component({})
export default class SynchronizeCompanyDialog extends Vue {

    @PropSync('dialog', { type: Boolean }) syncedDialog!: boolean

    lang: any = getModule(LangModule).lang
    loading: boolean = false

    connectorId: string = ""

    async synchronizeCompany(connectorId: string) {
        try {
            var something: any = null
            await Handler.getItem(this, something, () =>
                SynchroService.synchronizeCompany(connectorId)
            )
        } catch (e) {
            getModule(SnackbarModule).makeToast(this.lang.errorOccurred)
        }
        await SynchroService.synchronizeCompany(connectorId)
    }


}
