
























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Options from "@/models/vue/Options";
import Synchro, { SynchroType } from "@/models/Synchro";
import SynchroService from "@/services/SynchroService";
import { getModule } from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import SynchronizeCompanyDialog from "@/components/dialog/SynchronizeCompanyDialog.vue";

@Component({ components: { SynchronizeCompanyDialog } })
export default class extends Vue {
	progress: number = 0
	productsDialog: boolean = false
	lang: any = getModule(LangModule).lang
	synchros: Synchro[] = []
	page: number = 1
	pageCount: number = 0
	itemsPerPage: number = 10
	loading: boolean = false
	search: string = ""
	headers = [
		{ text: this.lang.type, value: "type", width: "auto", align: "center" },
		{ text: this.lang.start, value: "starts", width: "auto", align: "center" },
		{ text: this.lang.end, value: "finish", width: "auto", align: "center" },
		{ text: "Estado", value: "status", width: "auto", align: "center" },
		{ text: "Log", value: "log", width: "auto", align: "center" },
		{ text: "Id", value: "id", width: "auto", align: "center" },
	]
	options: Options = new Options()
	totalItems: number = 0
	companiesLoading: boolean = false
	productsLoading: boolean = false
	syncCompanyDialog: boolean = false

	openSynchro(synchro: Synchro) {
		this.$router.push("/synchro/" + synchro.id)
	}

	pingXGEST() {
		SynchroService.pingXGEST()
	}

	async synchronizeCompanies() {
		await SynchroService.synchronizeCompanies()
	}

	async synchronizeProducts() {
		await SynchroService.synchronizeAllProducts()
	}

	refresh() {
		SynchroService.getSynchros(this, this.synchros, this.page - 1, this.itemsPerPage, null)
	}

	@Watch("options")
	watchOptions() {
		this.refresh()
	}

	@Watch('productsDialog')
	onDialogChanged() {
		this.progress = 0
	}
}
